import React, { Fragment, useEffect, useState } from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import { EarthEuropeAfricaFilled20, Checkmark16 } from "@carbon/icons-react"
import { Menu, Transition } from "@headlessui/react"
import { useTranslation } from "react-i18next"

import { privacyLinks } from "../../../../utilities/privacy-links"

const websitesMapping = require("../../../../../config/websites.js")
const websites = websitesMapping["websitesMapping"]

const FooterWithItems = ({ navData }) => {
  const [currentWebsite, setCurrentWebsite] = useState(null)
  const [otherWebsites, setOtherWebsites] = useState(null)
  const { t, i18n } = useTranslation()

  const navColumnArray =
    navData?.filter((item) => {
      return item.component !== "meta_nav"
    }) || []
  const metaNav =
    navData?.find((item) => {
      return item.component === "meta_nav"
    }) || null

  let navColumns = []
  let lastColIndex = navColumnArray?.length - 2

  navColumnArray?.forEach((item, index) => {
    if (index === 0) {
      if (navColumnArray[1]) {
        navColumns.push({ navItems: [navColumnArray[0], navColumnArray[1]] })
      } else {
        navColumns.push({ navItems: [navColumnArray[0]] })
      }
    } else if (index === lastColIndex) {
      navColumns.push({
        navItems: [
          navColumnArray[lastColIndex],
          navColumnArray[lastColIndex + 1],
        ],
      })
    } else if (
      index >= 2 &&
      index < lastColIndex &&
      item.component === "nav_item"
    ) {
      navColumns.push({ navItems: [item] })
    }
  })

  useEffect(() => {
    if (typeof window !== "undefined") {
      setCurrentWebsite(
        websites.find(
          (w) =>
            w.url.replace("https://rothenberger.com", "").split("/")[1] ===
            window.location.pathname.split("/")[1]
        ) || null
      )
      setOtherWebsites(websites)
    }
  }, [])

  const openKlaro = () => {
    if (typeof window !== "undefined") {
      let countryLang = window.location.pathname.split("/")[1]

      return window?.klaro?.show({
        ...window.klaroConfig,
        ...{ lang: i18n.language },
        ...{ privacyPolicy: { default: privacyLinks[countryLang] } },
      })
    }
  }

  return (
    <footer className="text-gray-400 bg-gray-800">
      {/* Sitemap */}
      <div className="mx-auto text-sm max-w-screen-2xl px-grid">
        <div className="flex flex-row flex-wrap py-10 -mx-grid">
          <div className="w-full mb-8 md:mb-0 md:order-last px-grid md:w-1/2 lg:w-1/4">
            {currentWebsite !== null && (
              <div className="relative bg-gray-700">
                <Link
                  link="https://rothenberger.com/websites"
                  className="text-gray-200 btn hover:text-white"
                >
                  <EarthEuropeAfricaFilled20 className="inline-block mr-2 -mt-0.5 align-middle" />
                  {currentWebsite && currentWebsite.title}
                </Link>
              </div>
            )}
          </div>

          {navColumns?.map((navCol, colIndex) => {
            return (
              <div
                className="w-full mb-4 lg:mb-0 md:w-1/2 lg:w-1/4"
                key={`footer-col-${colIndex}`}
              >
                {navCol.navItems.map((item, index) => {
                  return (
                    <React.Fragment key={`footer-col-content-${index}`}>
                      {item.component === "nav_item" && (
                        <>
                          {item?.link?.cached_url === "" ? (
                            <p className="block px-4 pt-6 mb-4 font-bold text-white">
                              {item.title}
                            </p>
                          ) : (
                            <Link
                              className="block px-4 pt-6 mb-4 font-bold text-white"
                              link={item.link.cached_url}
                            >
                              {item.title}
                            </Link>
                          )}
                          {item?.sub_navigation.length > 0 &&
                            item.sub_navigation.map((subItem, index) => {
                              return (
                                <div key={index}>
                                  <Link
                                    className="block px-4 mb-4 hover:text-white"
                                    link={subItem.link.cached_url}
                                  >
                                    {subItem.title}
                                  </Link>
                                </div>
                              )
                            })}
                        </>
                      )}
                    </React.Fragment>
                  )
                })}
              </div>
            )
          })}
        </div>
        <div className="w-full border-t border-gray-400 px-grid" />
        {/* Meta-Nav */}
        <div className="flex flex-row flex-wrap justify-between py-4 text-xs -mx-grid">
          <div className="flex flex-row flex-wrap w-full xl:w-auto">
            <a
              key="cookieManager"
              className="py-4 px-grid hover:text-white whitespace-nowrap"
              onClick={() => {
                return openKlaro()
              }}
              href="javascript:;"
            >
              {t("generic.user_consent.manage_cookies")}
            </a>
            {metaNav && (
              <>
                {metaNav?.content?.map((content, index) => {
                  return (
                    <Link
                      key={index}
                      className="py-4 show-when-consent px-grid hover:text-white whitespace-nowrap"
                      link={content.link.cached_url}
                    >
                      {content.title}
                    </Link>
                  )
                })}
              </>
            )}
          </div>
          <span className="block w-full py-4 show-when-consent px-grid xl:order-first xl:w-auto">
            © {new Date().getFullYear()}
            {i18n.language === "ro"
              ? " ROWALT S.R.L."
              : " ROTHENBERGER Werkzeuge GmbH"}
          </span>
        </div>
      </div>
    </footer>
  )
}

export default FooterWithItems
