import React, { useContext, useEffect, useState } from "react"
// import SEO from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/Seo"
import Header from "./Header"
import { Helmet } from "react-helmet"
import Footer from "./Footer"

import { NavContext } from "@yumgmbh/gatsby-plugin-sb-nav/src/context/NavContext"
import { changeLanguage } from "i18next"

// should default to https://rothenberger.com/privacy

import { privacyLinks } from "../../../../utilities/privacy-links"
import { imprintLinks } from "../../../../utilities/imprint-links"
import LangHint from "./LangHint"

const websitesRegionsMap = require("../../../../../config/websiteRegions")
const websiteRegionsMapping = websitesRegionsMap["websiteRegionsMapping"]

const toggleEtrackerCookie = (klaroManager) => {
  let etrackerAllowed = klaroManager.getConsent("etrackerjourney")
  if (etrackerAllowed === true) {
    if (window._etracker) {
      console.log("DEBUG enable etracker cookie")
      window._etracker.enableCookies(process.env.GATSBY_ETRACKER_COOKIE_DOMAIN)
    }
  } else {
    if (window._etracker && window._etracker.areCookiesEnabled()) {
      console.log("DEBUG disable etracker cookie")
      window._etracker.disableCookies(process.env.GATSBY_ETRACKER_COOKIE_DOMAIN)
    }
  }
}

const Layout = ({ children, language }) => {
  const { navData } = useContext(NavContext) || { navData: {} }
  const [cookieConsentLight, setCookieConsentLight] = useState(false)

  // set i18n-language
  // set klaro-language
  useEffect(() => {
    changeLanguage(language)
    if (typeof window !== "undefined") {
      if (window.klaro && window.klaro.render && window.klaroConfig) {
        //window.klaro.defaultConfig.lang = language
        let countryLang = window.location.pathname.split("/")[1]

        // check for impressum and datenschutz + dont block interaction with the site
        if (
          window.location.pathname.indexOf(privacyLinks[countryLang]) !== -1 ||
          window.location.pathname.indexOf(imprintLinks[countryLang]) !== -1
        ) {
          setCookieConsentLight(true)
        }

        setTimeout(() => {
          if (countryLang !== "de-de") {
            window.klaroConfig.services = window.klaroConfig.services.filter(
              (el) => el.name !== "adformtracking"
            )
            window.klaroConfig.services = window.klaroConfig.services.filter(
              (el) => el.name !== "facebookpixel"
            )
          }

          window.klaro.render({
            ...window.klaroConfig,
            ...{ lang: language },
            ...{ privacyPolicy: { default: privacyLinks[countryLang] } },
          })

          if (window.klaro.getManager) {
            let manager = window.klaro.getManager()
            manager.applyConsents()
            if (manager && manager.getConsent) {
              toggleEtrackerCookie(manager)
            }
            manager.watch({
              update: (data, type) => {
                if (type === "savedConsents") {
                  toggleEtrackerCookie(manager)
                }
              },
            })
          }

          if (document?.location?.pathname === "/storyblok-preview/") {
            setTimeout(() => {
              if (document.querySelector(".cm-btn.cm-btn-success")) {
                document.querySelector(".cm-btn.cm-btn-success").click()
              }
            }, 100)
          }
        }, 50)
      }
    }
  }, [language, children])

  return (
    <>
      <Header navData={navData?.mainNavigation?.navigation} />
      {/* <SEO title="Test"></SEO> */}
      <Helmet
        htmlAttributes={{ lang: language }}
        bodyAttributes={{
          class: `theme-default ${cookieConsentLight ? "klaro-light" : ""}`,
        }}
      >
        <script type="application/ld+json">
          {`{
            "@context" : "https://schema.org",
            "@type" : "WebSite",
            "name" : "ROTHENBERGER",
            "url" : "https://rothenberger.com/"
          }`}
        </script>

        {websiteRegionsMapping &&
          websiteRegionsMapping.length > 0 &&
          websiteRegionsMapping.map((reg) => {
            return (
              <link
                key={reg.hrefLang}
                rel="alternate"
                href={reg.url}
                hrefLang={reg.hrefLang}
              />
            )
          })}
        <link
          rel="alternate"
          href="https://rothenberger.com/websites/"
          hrefLang="x-default"
        />
        <script
          type="text/plain"
          data-name="googleanalytics"
          data-type="application/javascript"
        >
          {`
            (function(i, s, o, g, r, a, m) {
              i['GoogleAnalyticsObject'] = r;
              i[r] = i[r] || function() {
                (i[r].q = i[r].q || []).push(arguments)
              }, i[r].l = 1 * new Date();
              a = s.createElement(o),
                m = s.getElementsByTagName(o)[0];
              a.async = 1;
              a.src = g;
              m.parentNode.insertBefore(a, m)
            })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

            ga('create', '${process.env.GATSBY_GA_KEY}', 'auto');
            ga('set', 'anonymizeIp', true);
            ga('send', 'pageview');
          `}
        </script>
        <script
          type="text/plain"
          data-name="adformtracking"
          data-type="application/javascript"
        >
          {` window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
            window._adftrack.push({
                HttpHost: 'track.adform.net',
                pm: 2861695,
                divider: encodeURIComponent('|'),
                pagename: encodeURIComponent('Global Tracking')
            });
            (function () { var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();
          `}
        </script>
        <script
          type="text/plain"
          data-name="facebookpixel"
          data-type="application/javascript"
        >
          {`!(function (f, b, e, v, n, t, s) {
              if (f.fbq) return;
              n = f.fbq = function () {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
              };
              if (!f._fbq) f._fbq = n;
              n.push = n;
              n.loaded = !0;
              n.version = "2.0";
              n.queue = [];
              t = b.createElement(e);
              t.async = !0;
              t.src = v;
              s = b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t, s);
            })(
              window,
              document,
              "script",
              "https://connect.facebook.net/en_US/fbevents.js"
            );
            fbq("init", "325618258312207");
            fbq("track", "PageView");
          `}
        </script>
        <script
          type="text/plain"
          data-name="googleanalytics"
          data-type="application/javascript"
        >
          {` (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${process.env.GATSBY_GTM_KEY}');`}
        </script>
        <script
          type="text/plain"
          data-name="clarity"
          data-type="application/javascript"
        >
          {` (function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "lc0w75juuu");`}
        </script>
      </Helmet>
      <main>{children}</main>
      <LangHint />
      <Footer navData={navData?.footer} />
    </>
  )
}

export default Layout
