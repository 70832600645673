import React, { useRef, useState, useEffect } from "react"
import { Image, Transformation } from "cloudinary-react"

const CloudinaryImage = ({
  image,
  type,
  imageWidth,
  imageHeight,
  fillColor,
  gravity,
  className,
  alt,
  title,
  aspectRatio,
  lazyLoad = true,
}) => {
  const [containerWidth, setContainerWidth] = useState(null)
  const [loading, setLoading] = useState(true)

  const containerRef = useRef(null)

  const calcContainerWidth = () => {
    const width = containerRef.current.clientWidth
    setContainerWidth(Math.ceil(width / 50) * 50)
    //console.log(containerWidth)
  }

  const ar = aspectRatio?.length > 0 ? aspectRatio.replace("by", ":") : ""

  useEffect(() => {
    if (containerRef.current && !imageWidth) {
      calcContainerWidth()
      setLoading(false)
    }
  }, [containerRef, calcContainerWidth, loading])

  return (
    <div ref={containerRef} className={className}>
      <Image
        cloudName={process.env.CLOUDINARY_NAME}
        publicId={image}
        type="fetch"
        className={className}
        alt={alt}
        title={title ? title : alt}
        loading={lazyLoad ? "lazy" : "eager"}
        secure={true}
      >
        <Transformation
          quality="auto:good"
          gravity={gravity ? gravity : fillColor ? "" : "face"}
          width={imageWidth ? imageWidth : containerWidth}
          height={imageHeight ? imageHeight : ""}
          background={fillColor ? `#${fillColor}` : ""}
          crop={fillColor ? "pad" : "fill"}
          dpr="auto"
          aspectRatio={ar}
        />
        <Transformation fetchFormat="auto" />
      </Image>
    </div>
  )
}

export default CloudinaryImage
