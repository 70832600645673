import React from "react"

import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import { Highlight, Snippet } from "react-instantsearch-dom"

const SearchResultCard = ({hit}) => {
  return (
    <div className="w-full py-4 bg-white">
      <div className="mb-4 px-grid">
        <Link className="flex flex-row flex-wrap -mx-grid group" link={hit.full_slug}>

          <div className="w-full md:w-1/2 lg:w-3/4 px-grid">
            <Headline
              className="block group-hover:text-primary-hover"
              tagName="h2"
              headlineLevel="h4"
              headline={hit.headline}
            />
            <span className="block mb-2 text-sm text-gray-300">
              {hit.full_slug}
            </span>
            {hit.description && (
              <p className="prose max-w-none line-clamp-5">
                ... <Snippet hit={hit} attribute="description" tagName="mark" separator=" - " /> ...
              </p>
            )}
          </div>
        </Link>
      </div>
    </div>
  )
}

export default SearchResultCard
