const privacyLinks = {
  "ae-en": "/ae-en/data-protection",
  "au-en": "/au-en/data-protection",
  "at-de": "/at-de/datenschutz",
  "be-fr": "/be-fr/protection-des-donnees",
  "be-nl": "/be-nl/gegevensbescherming",
  "bg-bg": "/bg-bg/zashtita-na-dannite",
  "br-pt": "/br-pt/protecao-de-dados",
  "ch-de": "/ch-de/datenschutz",
  "ch-fr": "/ch-fr/protection-des-donnees",
  "ch-it": "/ch-it/privacy-policy",
  "cz-cz": "/cz-cz/ochrana-dat",
  "es-es": "/es-es/proteccion-de-datos",
  "fr-fr": "/fr-fr/protection-des-donnees",
  "hu-hu": "/hu-hu/adatvedelem",
  "in-en": "/in-en/data-protection",
  "it-it": "/it-it/privacy-policy",
  "nl-nl": "/nl-nl/gegevensbescherming",
  "pl-pl": "/pl-pl/ochrona-danych",
  "pt-pt": "/pt-pt/proteccao-de-dados",
  "rs-en": "/rs-en/data-protection",
  "scandi-en": "/scandi-en/data-protection",
  "sk-sk": "/sk-sk/rechtliches_sk/ochrana-udajov",
  "tr-tr": "/tr-tr/data-protection",
  "gb-en": "/gb-en/data-protection",
  "us-en": "/us-en/data-protection",
  "za-en": "/za-en/data-protection",
  "de-de": "/de-de/datenschutz",
  rfo: "/de-de/datenschutz",
}

export { privacyLinks }
