import React, { useState, useEffect, createConnector } from "react"
import MetaNav from "./MetaNav"
import Search from "./Search"
import SearchResultsOverlay from "./SearchResultsOverlay"
import MainNav from "./MainNav"
import Logo from "./Logo"

import MobileNav from "./MobileNav"

import useMediaQuery from "@yumgmbh/gatsby-theme-yum-components/src/hooks/useMediaQuery"

import UserPanel from "../../../../components/molecules/UserPanel"

import queryString from "query-string"
import algoliasearch from "algoliasearch"
import {
  InstantSearch,
  Configure,
  hits,
  connectHits,
  connectMenu,
} from "react-instantsearch-core"
import { useTranslation } from "react-i18next"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

export const ALGOLIA_HITS_PER_PAGE = 10
export const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const VirtualMenu = connectMenu(() => null)

const HeaderNarrow = ({ navData }) => {
  const [menuState, setMenuState] = useState(null)
  const [showSearchResultsState, setShowSearchResultsState] = useState(false)

  const { i18n } = useTranslation()

  const menuClickHandler = (state) => {
    if (menuState === state) {
      setMenuState(null)
    } else {
      setMenuState(state)
    }
  }

  // Algolia Search
  let initialQuery = ""
  if (typeof window !== "undefined") {
    initialQuery = queryString.parse(window.location.search).query || ""
  }
  if (initialQuery) {
    if (showSearchResultsState !== true) {
      setShowSearchResultsState(true)
    }
  }
  const [query, setQuery] = useState(initialQuery)
  const options = {
    ...{
      query,
      filters: "",
      attributesToSnippet: ["name", "description:30"],
      //facetingAfterDistinct: true,
      hitsPerPage: 1000,
    },
  }

  const searchClient = {
    search(requests) {
      if (requests.every(({ params }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            processingTimeMS: 0,
          })),
        })
      }
      return algoliaClient.search(requests)
    },
  }
  // END of Algolia Search

  let isDesktop = useMediaQuery(`(min-width: 1056px)`)

  useEffect(() => {
    if (window && document) {
      gsap.registerPlugin(ScrollTrigger)

      let mainHeader = document.querySelector(".header-main")

      if (mainHeader) {
        let mainOffset = mainHeader.offsetHeight
        let mainElement = document.querySelector("main")

        ScrollTrigger.create({
          start: `top ${isDesktop ? "-32" : "0"}`,
          end: 99999,
          onEnter: () => {
            mainElement.style.paddingTop = `${isDesktop ? 96 : 48}px`
            mainHeader.classList.add("header-main-fixed")
          },
          onLeaveBack: () => {
            mainElement.style.paddingTop = null
            mainHeader.classList.remove("header-main-fixed")
          },
        })

        // only show when scrolling up
        // ScrollTrigger.create({
        //   start: "top -400",
        //   end: 99999,
        //   onUpdate: ({ direction }) => {
        //     if (direction == -1) {
        //       mainHeader.classList.add("header-main-fixed-show")
        //     } else {
        //       mainHeader.classList.remove("header-main-fixed-show")
        //     }
        //   },
        // })
      }
    }
  }, [])

  let categories = [{ title: "Universalwerkezeuge" }, { title: "Wartung" }]

  const website = `${process.env.GATSBY_GLOBAL_PATH_PREFIX}-${i18n.language}`

  return (
    <header className="w-full header-main">
      <InstantSearch
        searchClient={searchClient}
        indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
      >
        <Configure
          query={options.query}
          filters={options.filters}
          facetingAfterDistinct={options.facetingAfterDistinct}
          attributesToSnippet={options.attributesToSnippet}
          hitsPerPage={options.hitsPerPage}
          distinct
        />
        <VirtualMenu attribute="website" defaultRefinement={website} />

        <MobileNav
          categories={categories}
          menuClickHandler={menuClickHandler}
          setShowSearchResultsState={setShowSearchResultsState}
          showSearchResultsState={showSearchResultsState}
          setMenuState={setMenuState}
          setQuery={setQuery}
          query={query}
          menuState={menuState}
          navData={navData}
        />
        {/* Meta-Nav */}
        <MetaNav menuClickHandler={menuClickHandler} menuState={menuState} />
        {/* ENDE Meta-Nav */}
        {/* Logo-Search-Bar */}

        <div className="relative hidden w-full bg-white lg:block">
          <div className="flex flex-row mx-auto max-w-screen-2xl">
            <div className="flex flex-row mr-auto">
              <Logo className="h-16 px-4 pt-5 pb-2" />
              {/* Main-Nav */}
              <MainNav
                menuState={menuState}
                menuClickHandler={menuClickHandler}
                navData={navData}
              />
            </div>
            <Search
              menuClickHandler={menuClickHandler}
              setMenuState={setMenuState}
              menuState={menuState}
              categories={categories}
              setShowSearchResultsState={setShowSearchResultsState}
              showSearchResultsState={showSearchResultsState}
              setQuery={setQuery}
              query={query}
            />
            {/* ENDE Suche */}
            <div className="flex flex-row py-2 -ml-4">
              <UserPanel />
            </div>
          </div>
          {/* Search Resulsts */}
          <SearchResultsOverlay
            categories={categories}
            setShowSearchResultsState={setShowSearchResultsState}
            showSearchResultsState={showSearchResultsState}
            query={query}
          />
          {/* END Search Resulsts */}
        </div>
        {/* ENDE Logo-Search-Bar */}
      </InstantSearch>
    </header>
  )
}

export default HeaderNarrow
