import React, { useState, useEffect } from "react"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import { useTranslation } from "react-i18next"

const LogoOld = ({ className }) => {
  const { i18n } = useTranslation()
  const [logoLink, setLogoLink] = useState(null)

  useEffect(() => {
    if (
      process.env.GATSBY_GLOBAL_PATH_PREFIX &&
      process.env.GATSBY_GLOBAL_PATH_PREFIX_DELIMITER
    ) {
      setLogoLink(
        `/${process.env.GATSBY_GLOBAL_PATH_PREFIX}${process.env.GATSBY_GLOBAL_PATH_PREFIX_DELIMITER}${i18n.language}`
      )
    } else {
      setLogoLink("/")
    }
  }, [i18n, i18n.language])

  return (
    <Link
      className={`block h-full ${className}`}
      link={logoLink}
      isCrossWebsiteLink={true}
    >
      <Image
        className="block w-auto h-full"
        forceLoad={true}
        image={{
          filename: `${process.env.GATSBY_ORIGINAL_HOST}/ro-logo.svg`,
          alt: "Rothenberger",
        }}
      />
    </Link>
  )
}

export default LogoOld
