import React from "react"
import HeaderNarrow from "./HeaderNarrow"
import HeaderOld from "./HeaderOld"

const Header = ({ navData }) => {
  if (
    navData &&
    navData[0]?.sub_navigation?.findIndex(
      (element) => element.component === "nav_column"
    ) !== -1
  ) {
    return <HeaderNarrow navData={navData} />
  } else if (navData) {
    return <HeaderOld navData={navData} />
  } else {
    return <div></div>
  }
}

export default Header
