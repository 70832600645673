import React from "react"
import ReactMarkdown from "react-markdown"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const Richtext = ({ text, flow, className }) => {
  const LinkComponent = ({ children, href }) => (
    <Link link={href}>{children}</Link>
  )

  return (
    <>
      {text && text.length > 0 && (
        <ReactMarkdown
          children={text}
          className={`prose max-w-none ${className ? className : ""} ${
            flow === "true" ? "sm:columns-2 sm:gap-8" : ""
          }`}
          components={{ a: LinkComponent }}
        />
      )}
    </>
  )
}

export default Richtext
