import React, { Fragment } from "react"
import { Menu, Transition } from "@headlessui/react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import { modifyInternalLinkUrl } from "../../../../utilities/frontend-utils"

import { navigate } from "gatsby"

import { ChevronDown16, ChevronRight32 } from "@carbon/icons-react"

const MainNavOld = ({ navData }) => {
  return (
    <div className="hidden w-full bg-white md:block">
      <div className="flex flex-row mx-auto max-w-screen-2xl">
        {navData?.map((item, index) => (
          <Menu key={index} as="div" className="relative">
            {({ open }) => (
              <>
                {item?.sub_navigation?.length > 0 ? (
                  <Menu.Button
                    className={`py-5 group btn hover:text-primary ${
                      open ? "text-primary" : "text-gray-800"
                    }`}
                  >
                    <span
                      onClick={(e) => {
                        if (item?.link.cached_url) {
                          navigate(modifyInternalLinkUrl(item?.link.cached_url))
                          e.stopPropagation()
                          return false
                        }
                      }}
                    >
                      {item.title}
                    </span>
                    <ChevronDown16
                      className={`inline-block ml-2 -mt-0.5 align-middle group-hover:mt-0.5 transition-all duration-300 transform ${
                        open ? "rotate-180" : ""
                      }`}
                    />
                  </Menu.Button>
                ) : (
                  <Link
                    className="py-5 text-gray-800 btn hover:text-primary"
                    link={`/${item.link?.cached_url}`}
                  >
                    {item.title}
                  </Link>
                )}
                {item?.sub_navigation?.length > 0 && (
                  <Transition
                    as={Fragment}
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-50 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-50 opacity-0"
                  >
                    <Menu.Items
                      as="ul"
                      className="absolute left-0 right-auto z-50 min-w-full px-4 origin-top-left shadow-lg bg-gray-50 top-full whitespace-nowrap"
                    >
                      {item?.sub_navigation.map((subItem, index) => {
                        return (
                          <Menu.Item as="li" key={index}>
                            {subItem?.component === "nav_item" ? (
                              <Menu as="div" className="relative">
                                {({ open }) => (
                                  <>
                                    {subItem.sub_navigation.length > 0 ? (
                                      <Menu.Button
                                        className={`flex items-center justify-between w-full px-0 text-left btn hover:text-primary ${
                                          open
                                            ? "text-primary"
                                            : "text-gray-800"
                                        }`}
                                      >
                                        <span
                                          onClick={(e) => {
                                            if (subItem.link?.cached_url) {
                                              navigate(
                                                modifyInternalLinkUrl(
                                                  subItem.link.cached_url
                                                )
                                              )
                                              e.stopPropagation()
                                              return false
                                            }
                                          }}
                                        >
                                          {subItem.title}
                                        </span>
                                        <ChevronRight32
                                          className={`inline-block ml-auto h-4 align-middle transition-transform duration-300 transform ${
                                            open ? "rotate-180" : ""
                                          }`}
                                        />
                                      </Menu.Button>
                                    ) : (
                                      <Link
                                        className="px-0 text-left text-gray-800 btn hover:text-primary"
                                        link={`/${subItem.link?.cached_url}`}
                                      >
                                        {subItem.title}
                                      </Link>
                                    )}
                                    {subItem?.sub_navigation.length > 0 && (
                                      <Transition
                                        as={Fragment}
                                        enter="transition duration-100 ease-out"
                                        enterFrom="transform scale-50 opacity-0"
                                        enterTo="transform scale-100 opacity-100"
                                        leave="transition duration-75 ease-out"
                                        leaveFrom="transform scale-100 opacity-100"
                                        leaveTo="transform scale-50 opacity-0"
                                      >
                                        <Menu.Items
                                          as="ul"
                                          className="absolute top-0 z-50 min-w-full px-4 ml-4 origin-top-left shadow-lg left-full bg-gray-50 whitespace-nowrap"
                                        >
                                          {subItem.sub_navigation.map(
                                            (subSubItem, index) => {
                                              return (
                                                <Menu.Item as="li" key={index}>
                                                  <Link
                                                    className="block px-0 text-gray-800 btn hover:text-primary border-full"
                                                    link={
                                                      subSubItem.link
                                                        ?.cached_url
                                                    }
                                                  >
                                                    {subSubItem.title}
                                                  </Link>
                                                </Menu.Item>
                                              )
                                            }
                                          )}
                                        </Menu.Items>
                                      </Transition>
                                    )}
                                  </>
                                )}
                              </Menu>
                            ) : (
                              <Link
                                className="w-full px-0 text-left text-gray-800 btn hover:text-primary"
                                link={subItem.link?.cached_url}
                              >
                                {subItem.title}
                              </Link>
                            )}
                          </Menu.Item>
                        )
                      })}
                    </Menu.Items>
                  </Transition>
                )}
              </>
            )}
          </Menu>
        ))}
      </div>
    </div>
  )
}

export default MainNavOld
