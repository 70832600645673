import React, { useState, useEffect } from "react"
import { getCookie, setCookie } from 'react-use-cookie'
import { useTranslation } from "react-i18next"

import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const LangHint = ({}) => {
  const { i18n, t } = useTranslation()

  // wird für i18n und das Cookie benötigt
  const websiteLangCode = process.env.GATSBY_GLOBAL_PATH_PREFIX + "_" + i18n.language

  // siehe: websites/www/static/_redirects
  const websiteCookieName = "redirect_" + websiteLangCode
  const websiteCookie = getCookie(websiteCookieName)

  // => sobald dieses Cookie vorhanden ist, soll der Hinweis nicht mehr angezeigt werden
  const [showLangHint, setShowLangHint] = useState(false)

  const acceptWebsite = () => {
    setCookie(websiteCookieName, 'd', {
      days: 365,
      SameSite: 'Lax'
    })
    setShowLangHint(false)
  }

  useEffect(() => {
    if(!websiteCookie) {
      setShowLangHint(true)
    } else {
      setShowLangHint(false)
    }
  }, [websiteCookie])

  return (
    <>
      {showLangHint && (
        <div className="sticky z-40 w-full bottom-[4.5rem]">
          <div className="relative block-w-container">
            <div className="absolute bottom-0 right-0 z-40 px-4 py-8 bg-gray-100 border border-t-0 border-gray-100 max-w-screen md:max-w-md">
              <p className="block text-base text-black ">
                {t("websites_hint." + websiteLangCode + ".hint")}
              </p>
              <Link
                className="py-2 mt-4 mr-4 btn btn-secondary"
                link="https://www.rothenberger.com/websites"
              >
                {t("websites_hint." + websiteLangCode + ".button_change_country_yes")}
              </Link>
              <button
                onClick={() => acceptWebsite()}
                className="py-2 mt-4 btn btn-primary"
              >
                {t("websites_hint." + websiteLangCode + ".button_change_country_no")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default LangHint
