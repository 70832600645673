import React, { Fragment, useState } from "react"
import { ChevronDown16 } from "@carbon/icons-react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

const MobileMenuAccordion = ({
  item,
  index,
  openAccordionState,
  setOpenAccordionState,
}) => {
  //const [isOpenState, setIsOpenState] = useState(false)
  return item.sub_navigation?.length > 0 ? (
    <>
      <button
        className="flex w-full font-bold text-left text-gray-800 border-t btn hover:text-primary border-100"
        onClick={() =>
          setOpenAccordionState(openAccordionState === index ? null : index)
        }
      >
        {item.title}
        <ChevronDown16
          className={`inline-block h-6 ml-auto -mt-0.25 align-middle transition-transform duration-300 transform ${
            openAccordionState === index ? "rotate-180" : ""
          }`}
        />
      </button>
      <div
        className={`${
          openAccordionState === index ? "" : "overflow-hidden h-0"
        }`}
      >
        <div className="flex flex-row flex-wrap x-mx-grid bg-gray-50">
          {item?.sub_navigation?.map((navCol, colIndex) => (
            <div className="w-full md:w-1/2 md:mb-4 " key={colIndex}>
              {navCol?.items?.map((subItem, subItemIndex) => (
                <React.Fragment key={subItemIndex}>
                  {subItem?.component === "nav_highlight" && (
                    <Link
                      link={subItem.link}
                      className="block px-4 text-gray-800 btn group"
                    >
                      <span className="inline-block mb-2 font-semibold md:mb-4 group-hover:text-primary">
                        {subItem.title}
                      </span>
                      {subItem?.image?.filename?.length > 0 && (
                        <Image
                          className="hidden w-full h-auto mb-2 md:block"
                          forceLoad={true}
                          image={subItem?.image}
                        />
                      )}
                      {subItem.text?.length > 0 && (
                        <span className="block text-sm">{subItem.text}</span>
                      )}
                    </Link>
                  )}

                  {subItem?.component === "nav_card" && (
                    <div className="px-4 py-4">
                      <Link
                        link={subItem.link}
                        className="block w-full h-full bg-white group"
                      >
                        <Image
                          className="hidden md:block"
                          forceLoad={true}
                          image={subItem?.image}
                        />
                        <div className="flex flex-col min-h-[10rem] justify-between p-4">
                          <div>
                            <span className="font-bold group-hover:text-primary">
                              {subItem.title}
                            </span>
                            <span className="block mt-2 text-sm">
                              {subItem.text}
                            </span>
                          </div>
                          <span className="block text-sm text-primary">
                            {subItem.link_text}
                          </span>
                        </div>
                      </Link>
                    </div>
                  )}

                  {subItem?.component === "nav_item" && (
                    <>
                      <Link
                        className="block px-4 font-semibold text-gray-800 btn hover:text-primary"
                        link={subItem.link}
                        key={subItemIndex}
                      >
                        {subItem.title}
                      </Link>
                      {subItem?.sub_navigation?.map(
                        (subSubItem, subSubItemIndex) => (
                          <Link
                            className="block pl-8 pr-4 text-gray-800 md:pl-8 btn hover:text-primary"
                            link={subSubItem.link}
                            key={subSubItemIndex}
                          >
                            {subSubItem.title}
                          </Link>
                        )
                      )}
                    </>
                  )}
                </React.Fragment>
              ))}
            </div>
          ))}
        </div>
        {/* {item.sub_navigation?.map((subitem, index) => (
          <Fragment key={index}>
            <Link
              className="block px-4 font-semibold text-gray-800 bg-gray-50 btn hover:text-primary"
              link={subitem.link}
            >
              {subitem.title}
            </Link>
            {subitem.sub_navigation?.length > 0 &&
              subitem.sub_navigation?.map((secsubitem, index) => (
                <Link
                  key={index}
                  className="block pl-8 text-gray-800 bg-gray-50 btn hover:text-primary"
                  link={secsubitem.link}
                >
                  {secsubitem.title}
                </Link>
              ))}
          </Fragment>
        ))} */}
      </div>
    </>
  ) : (
    <Link
      className="flex w-full font-bold text-left text-gray-800 border-t btn hover:text-primary border-100"
      link={`/${item.link?.cached_url}`}
    >
      {item.title}
    </Link>
  )
}

export default MobileMenuAccordion
