import React from "react"
import { Link as GatsbyLink } from "gatsby"
import Icon from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Icon"

import { modifyInternalLinkUrl } from "../../../../utilities/frontend-utils"

const Link = ({
  link,
  className,
  activeClass,
  target,
  rel,
  onClick,
  children,
  externalIcon,
  isCrossWebsiteLink  = false
}) => {
  if (
    !link ||
    (typeof link === "object" &&
      link.url?.length === 0 &&
      link.cached_url?.length === 0)
  ) {
    return <span className={className}>{children}</span>
  }

  let linkUrl = ""
  if (typeof link === "object" && link.url?.length > 0) {
    linkUrl = link.url
  } else if (typeof link === "object" && link.cached_url?.length > 0) {
    linkUrl = `/${link.cached_url}`
  } else if (typeof link === "string") {
    linkUrl = link
  } else {
    linkUrl = ""
  }

  if (linkUrl.indexOf("@") !== -1) {
    return (
      <a
        className={className}
        href={(linkUrl.indexOf("mailto:") === -1 ? "mailto:" : "") + linkUrl}
        onClick={(e) => {
          if (onClick) {
            onClick(e)
          }
        }}
      >
        {children}
      </a>
    )
  }

  let linkTarget
  if (link.linktype && link.linktype === "story") {
    linkTarget = target
  } else {
    // force target=_blank for all other domains
    linkTarget = "_blank"
  }

  if (
    linkUrl.length < 1 ||
    linkUrl.indexOf("www") !== -1 ||
    linkUrl.indexOf("http") !== -1
  ) {
    return (
      <a
        href={linkUrl}
        target={linkTarget}
        className={className}
        onClick={(e) => {
          if (onClick) {
            onClick(e)
          }
        }}
        rel={rel}
      >
        {externalIcon && (
          <Icon
            icon={{ type: "fas", icon: "fa-external-link-alt" }}
            size="1x"
            className="pr-1"
          />
        )}
        {children}
      </a>
    )
  } else {
    return (
      <GatsbyLink
        className={className}
        activeClassName={activeClass}
        onClick={onClick}
        to={modifyInternalLinkUrl(linkUrl, isCrossWebsiteLink)}
      >
        {children}
      </GatsbyLink>
    )
  }
}

export default Link
