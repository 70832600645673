import React, { useState, Fragment } from "react"
import { Menu, Transition } from "@headlessui/react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

import { modifyInternalLinkUrl } from "../../../../utilities/frontend-utils"

import { navigate } from "gatsby"

import { ChevronDown16, ChevronRight32 } from "@carbon/icons-react"
import { Helmet } from "react-helmet"

const MainNav = ({ navData }) => {
  const [showOverlayState, setShowOverlayState] = useState(null)

  return (
    <div className="hidden w-full bg-white lg:block">
      {showOverlayState !== null && (
        <Helmet
          htmlAttributes={{
            class: "overflow-x-hidden overflow-hidden",
          }}
          bodyAttributes={{
            class: "theme-default font-body overflow-x-hidden overflow-hidden",
          }}
        />
      )}
      <div className="flex flex-row">
        {navData?.map((item, index) => (
          <Menu key={index} as="div">
            {({ open }) => (
              <>
                {open
                  ? setShowOverlayState(index)
                  : showOverlayState !== index
                  ? ""
                  : setShowOverlayState(null)}

                {item?.sub_navigation?.length > 0 ? (
                  <Menu.Button
                    className={`py-5 group btn hover:text-primary ${
                      open ? "text-primary" : "text-gray-800"
                    }`}
                  >
                    <span
                      onClick={(e) => {
                        if (item?.link.cached_url) {
                          navigate(modifyInternalLinkUrl(item?.link.cached_url))
                          e.stopPropagation()
                          return false
                        }
                      }}
                    >
                      {item.title}
                    </span>
                    <ChevronDown16
                      className={`inline-block ml-2 -mt-0.5 align-middle group-hover:mt-0.5 transition-all duration-300 transform ${
                        open ? "rotate-180" : ""
                      }`}
                    />
                  </Menu.Button>
                ) : (
                  <Link
                    className="py-5 text-gray-800 btn hover:text-primary"
                    link={item.link?.cached_url}
                  >
                    {item.title}
                  </Link>
                )}
                {item?.sub_navigation?.length > 0 && (
                  <Transition
                    as={Fragment}
                    enter="transition duration-200 ease-out"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition duration-150 ease-out"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div
                      className="absolute inset-x-0 z-50 top-full"
                      style={{ height: "calc(100vh - 6rem)" }}
                    >
                      <Menu.Items as="div" className="bg-gray-50">
                        <div className="px-4 pb-4 mx-auto max-w-screen-2xl">
                          <div className="flex flex-row flex-wrap -mx-grid">
                            {item?.sub_navigation?.map((navCol, colIndex) => (
                              <div
                                className={`w-full md:w-1/2 ${
                                  item?.sub_navigation?.length > 4 &&
                                  item?.sub_navigation?.length < 6
                                    ? "lg:w-1/5"
                                    : "lg:w-1/4"
                                }`}
                                key={colIndex}
                              >
                                {navCol?.items?.map((subItem, subitemIndex) => {
                                  return (
                                    <React.Fragment key={subitemIndex}>
                                      {subItem?.component ===
                                        "nav_highlight" && (
                                        <ul className="py-4">
                                          <Menu.Item as="li">
                                            <Link
                                              className="block py-3 px-grid group"
                                              link={subItem.link}
                                            >
                                              <span className="inline-block mb-4 text-lg group-hover:text-primary">
                                                {subItem.title}
                                              </span>
                                              {subItem?.image?.filename
                                                ?.length > 0 && (
                                                <Image
                                                  className="w-full h-auto mb-2"
                                                  forceLoad={true}
                                                  image={subItem?.image}
                                                  aspectRatio="4by3"
                                                />
                                              )}
                                              {subItem.text?.length > 0 && (
                                                <span className="inline-block text-sm">
                                                  {subItem.text}
                                                </span>
                                              )}
                                            </Link>
                                          </Menu.Item>
                                        </ul>
                                      )}

                                      {subItem?.component === "nav_card" && (
                                        <div className="pt-4">
                                          <div className="h-full py-4">
                                            <Menu.Item
                                              as="div"
                                              className="h-full px-grid"
                                            >
                                              <Link
                                                link={subItem.link}
                                                className="block w-full h-full bg-white group"
                                              >
                                                <Image
                                                  className="block"
                                                  forceLoad={true}
                                                  image={subItem?.image}
                                                  aspectRatio="4by3"
                                                />
                                                <div className="flex flex-col min-h-[10rem] justify-between p-4">
                                                  <div>
                                                    <span className="font-bold group-hover:text-primary">
                                                      {subItem.title}
                                                    </span>
                                                    <span className="block mt-2 text-sm">
                                                      {subItem.text}
                                                    </span>
                                                  </div>
                                                  <span className="block text-sm text-primary">
                                                    {subItem.link_text}
                                                  </span>
                                                </div>
                                              </Link>
                                            </Menu.Item>
                                          </div>
                                        </div>
                                      )}

                                      {subItem?.component === "nav_item" && (
                                        <ul className="py-4">
                                          {subItem.link?.cached_url.length >
                                          0 ? (
                                            <Menu.Item as="li">
                                              <Link
                                                className="block text-lg btn hover:text-primary"
                                                link={subItem.link}
                                              >
                                                {subItem.title}
                                              </Link>
                                            </Menu.Item>
                                          ) : (
                                            <Link
                                              className="block text-lg btn"
                                              link={subItem.link}
                                            >
                                              {subItem.title}
                                            </Link>
                                          )}
                                          {/* <Menu.Item as="li">
                                            <Link
                                              className={`block text-lg btn ${
                                                subItem.link?.cached_url
                                                  ?.length > 0
                                                  ? "hover:text-primary"
                                                  : "pointer-events-none"
                                              }`}
                                              link={subItem.link}
                                            >
                                              {subItem.title}
                                            </Link>
                                          </Menu.Item> */}
                                          {subItem?.sub_navigation?.map(
                                            (subSubItem, subSubItemIndex) => (
                                              <Menu.Item
                                                as="li"
                                                key={subSubItemIndex}
                                              >
                                                <Link
                                                  className="block py-1 btn hover:text-primary"
                                                  link={subSubItem.link}
                                                >
                                                  {subSubItem.title}
                                                </Link>
                                              </Menu.Item>
                                            )
                                          )}
                                        </ul>
                                      )}
                                    </React.Fragment>
                                  )
                                })}
                              </div>
                            ))}
                          </div>
                        </div>
                      </Menu.Items>
                    </div>
                  </Transition>
                )}
              </>
            )}
          </Menu>
        ))}
      </div>
      {showOverlayState !== null && (
        <div className="absolute z-30 top-full inset-x-0 h-[100vh] bg-black bg-opacity-50 backdrop-blur-[2px]"></div>
      )}
    </div>
  )
}

export default MainNav
