import React, { Fragment, useEffect, useState } from "react"
import { Menu, Transition } from "@headlessui/react"
import {
  ChevronDown16,
  Checkmark16,
  Favorite16,
  Location16,
  Renew16,
  EarthEuropeAfricaFilled16,
} from "@carbon/icons-react"
import { useTranslation } from "react-i18next"

import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const websitesMapping = require("../../../../../config/websites.js")
const websites = websitesMapping["websitesMapping"]

const MetaNavOld = () => {
  const [currentWebsite, setCurrentWebsite] = useState(null)
  const { t, i18n } = useTranslation()

  const dealerLinks = process.env.GATSBY_DEALER_SEARCH_PAGE_PATH
    ? JSON.parse(process.env.GATSBY_DEALER_SEARCH_PAGE_PATH)
    : null

  useEffect(() => {
    if (typeof window !== "undefined") {
      setCurrentWebsite(
        websites.find(
          (w) =>
            w.url.replace("https://rothenberger.com", "").split("/")[1] ===
            window.location.pathname.split("/")[1]
        ) || null
      )
    }
  }, [])

  if (!currentWebsite) {
    return null
  }

  return (
    <div className="hidden w-full text-gray-400 bg-gray-800 md:block">
      <div className="flex flex-row mx-auto max-w-screen-2xl">
        <Link
          link="https://rothenberger.com/websites"
          className="text-gray-200 btn hover:text-white"
        >
          <EarthEuropeAfricaFilled16 className="inline-block mr-2 -mt-0.5 align-middle" />
          {currentWebsite && currentWebsite.title}
        </Link>
        <div className="inline-block ml-auto">
          {/* <Link className="text-gray-200 btn hover:text-white" link="/">
            Merkzettel
            <Favorite16 className="inline-block ml-2 -mt-0.5 align-middle" />
          </Link>
          <span className="inline-block w-px h-12 align-bottom">
            <span className="inline-block w-px h-6 my-3 bg-gray-600"></span>
          </span>
          <Link className="text-gray-200 btn hover:text-white" link="/">
            Artikelvergleich
            <Renew16 className="inline-block ml-2 -mt-0.5 align-middle" />
          </Link>
          <span className="inline-block w-px h-12 align-bottom">
            <span className="inline-block w-px h-6 my-3 bg-gray-600"></span>
          </span> */}
          {dealerLinks && dealerLinks[i18n.language] && (
            <Link
              link={dealerLinks[i18n.language]}
              className="text-gray-200 btn hover:text-white"
            >
              {t("generic.dealer_map.title")}
              <Location16 className="inline-block ml-2 -mt-0.5 align-middle" />
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default MetaNavOld
