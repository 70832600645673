const imprintLinks = {
  "ae-en": "/ae-en/imprint",
  "au-en": "/au-en/imprint",
  "at-de": "/at-de/impressum",
  "be-fr": "/be-fr/mentions-legales",
  "be-nl": "/be-nl/opdruk",
  "bg-bg": "/bg-bg/imprint",
  "br-pt": "/br-pt/carimbo",
  "ch-de": "/ch-de/impressum",
  "ch-fr": "/ch-fr/mentions-legales",
  "ch-it": "/ch-it/privacy-policy",
  "cz-cz": "/cz-cz/firemni-udaje",
  "es-es": "/es-es/imprimir",
  "fr-fr": "/fr-fr/mentions-legales",
  "hu-hu": "/hu-hu/impresszum",
  "in-en": "/in-en/imprint",
  "it-it": "/it-it/imprint",
  "nl-nl": "/nl-nl/opdruk",
  "pl-pl": "/pl-pl/impressum",
  "pt-pt": "/pt-pt/carimbo",
  "rs-en": "/rs-en/imprint",
  "scandi-en": "/scandi-en/data-protection",
  "sk-sk": "/sk-sk/odtlacok",
  "tr-tr": "/tr-tr/imprint",
  "gb-en": "/gb-en/imprint",
  "us-en": "/us-en/imprint",
  "za-en": "/za-en/imprint",
  "de-de": "/de-de/impressum",
  rfo: "/de-de/impressum",
}

export { imprintLinks }
