// https://www.rothenberger.com/de-de
// https://www.rothenberger.com/de-de
// https://www.rothenberger.com/be-nl
// https://www.rothenberger.com/be-fr

const websitesMapping = [
  {
    title: "Australia – English",
    url: "https://rothenberger.com/au-en",
  },
  {
    title: "Belgium – Français (Belgique)",
    url: "https://rothenberger.com/be-fr",
  },
  {
    title: "Belgium – Nederlands (België)",
    url: "https://rothenberger.com/be-nl",
  },
  {
    title: "Bulgaria – Български (България)",
    url: "https://rothenberger.com/bg-bg",
  },
  {
    title: "Brasil – Português",
    url: "https://rothenberger.com/br-pt",
  },
  {
    title: "Czech Republic – Čeština (Česká Republika)",
    url: "https://rothenberger.com/cz-cz",
  },
  {
    title: "Germany – Deutsch (Deutschland)",
    url: "https://rothenberger.com/de-de",
  },
  {
    title: "Spain – Español (España)",
    url: "https://rothenberger.com/es-es",
  },
  {
    title: "France – Français",
    url: "https://rothenberger.com/fr-fr",
  },
  {
    title: "India – English",
    url: "https://rothenberger.com/in-en",
  },
  {
    title: "Italy – Italiano (Italia)",
    url: "https://rothenberger.com/it-it",
  },
  {
    title: "Hungary – Magyar (Magyarország)",
    url: "https://rothenberger.com/hu-hu",
  },
  {
    title: "Netherlands – Nederlands (Nederland)",
    url: "https://rothenberger.com/nl-nl",
  },
  {
    title: "Austria – Deutsch (Österreich)",
    url: "https://rothenberger.com/at-de",
  },
  {
    title: "Poland – Polski (Polska)",
    url: "https://rothenberger.com/pl-pl",
  },
  {
    title: "Portugal – Português",
    url: "https://rothenberger.com/pt-pt",
  },
  {
    title: "România – Română",
    url: "https://rothenberger.com/ro-ro",
  },
  {
    title: "Sweden – English",
    url: "https://rothenberger.com/scandi-en",
  },
  {
    title: "Iceland – English",
    url: "https://rothenberger.com/scandi-en",
  },
  {
    title: "Switzerland – Deutsch (Schweiz)",
    url: "https://rothenberger.com/ch-de",
  },
  {
    title: "Serbia – English",
    url: "https://rothenberger.com/rs-en",
  },
  {
    title: "Slovakia – Slovenský (Slovensko)",
    url: "https://rothenberger.com/sk-sk",
  },
  {
    title: "South Africa – English",
    url: "https://rothenberger.com/za-en",
  },
  {
    title: "Switzerland – Français (Suisse)",
    url: "https://rothenberger.com/ch-fr",
  },
  {
    title: "Switzerland – Italiano (Svizzera",
    url: "https://rothenberger.com/ch-it",
  },
  {
    title: "Turkey – Türkçe (Türkiye)",
    url: "https://rothenberger.com/tr-tr",
  },
  {
    title: "United Arab Emirates – English",
    url: "https://rothenberger.com/ae-en",
  },
  {
    title: "United Kingdom – English",
    url: "https://rothenberger.com/gb-en",
  },
  {
    title: "United States – English",
    url: "https://rothenberger.com/us-en",
  },
]

module.exports = {
  websitesMapping: websitesMapping,
}
