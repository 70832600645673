import React from "react"
import FooterWithItems from "./FooterWithItems"
import FooterWithColumns from "./FooterWithColumns"

const Footer = ({ navData }) => {
  if (navData?.navigation) {
    return <FooterWithItems navData={navData?.navigation} />
  } else if (navData?.columns) {
    return <FooterWithColumns navData={navData} />
  } else {
    return <div></div>
  }
}

export default Footer
