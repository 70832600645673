import React, { useState, useEffect } from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import UserPanel from "../../../../components/molecules/UserPanel"
import { useTranslation } from "react-i18next"

import { Close20, Menu20, Search20, Location20 } from "@carbon/icons-react"
import SearchResultsOverlay from "./SearchResultsOverlay"
import MobileMenuAccordionOld from "./MobileMenuAccordionOld"
import Search from "./Search"
import { Helmet } from "react-helmet"
import Logo from "./Logo"

const MobileNavOld = ({
  categories,
  setShowSearchResultsState,
  showSearchResultsState,
  setMenuState,
  menuState,
  navData,
  menuClickHandler,
  setQuery,
  query,
}) => {
  const [showSearchState, setShowSearchState] = useState(false)
  const { t, i18n } = useTranslation()
  const dealerLinks = process.env.GATSBY_DEALER_SEARCH_PAGE_PATH
    ? JSON.parse(process.env.GATSBY_DEALER_SEARCH_PAGE_PATH)
    : null

  useEffect(() => {
    setShowSearchState(showSearchResultsState)
  }, [showSearchResultsState])

  return (
    <div className="block md:hidden">
      <div className="relative flex flex-row justify-between">
        <div className="h-12 px-4 pt-4 pb-2">
          <Logo />
        </div>
        <div>
          <button
            className="px-4 py-3 "
            onClick={() => {
              setShowSearchState(true)
            }}
          >
            <Search20 className="inline-block -mt-0.5 align-middle" />
          </button>
          <button
            className="px-4 py-3 "
            onClick={() => {
              setMenuState(menuState === "mobile" ? null : "mobile")
            }}
          >
            {menuState === "mobile" ? (
              <Close20 className="inline-block -mt-0.5 align-middle" />
            ) : (
              <Menu20 className="inline-block -mt-0.5 align-middle" />
            )}
          </button>
        </div>
        <div
          className={`absolute top-12 bg-white shadow-lg z-50 w-full overflow-y-auto flex flex-col ${
            menuState === "mobile" ? "" : "hidden"
          }`}
          style={{ maxHeight: "calc(100vh - 3rem)" }}
        >
          {menuState === "mobile" && (
            <Helmet
              htmlAttributes={{
                class: "overflow-x-hidden overflow-hidden",
              }}
              bodyAttributes={{
                class:
                  "theme-default font-body overflow-x-hidden overflow-hidden",
              }}
            />
          )}
          {navData && (
            <div>
              {navData?.map((item, index) => (
                <MobileMenuAccordionOld key={index} item={item} />
              ))}
            </div>
          )}
          <div className="flex flex-row border-t">
            {dealerLinks && dealerLinks[i18n.language] && (
              <Link
                link={dealerLinks[i18n.language]}
                className="w-1/2 text-gray-500 btn hover:text-primary"
              >
                {t("generic.dealer_map.title")}
                <Location20 className="inline-block ml-1 -mt-0.5 align-middle" />
              </Link>
            )}
            <div className="w-1/2 text-gray-500">
              <UserPanel mobileVersion={true} />
            </div>
          </div>
        </div>

        <div
          className={`absolute w-full transition-all duration-300 ${
            showSearchState ? "" : "transform -translate-y-full"
          }`}
        >
          {showSearchState && (
            <>
              <Search
                menuClickHandler={menuClickHandler}
                setMenuState={setMenuState}
                menuState={menuState}
                categories={categories}
                setShowSearchResultsState={setShowSearchResultsState}
                showSearchResultsState={showSearchResultsState}
                setQuery={setQuery}
                query={query}
                isMobile={true}
              />
              <button
                className="absolute right-0 p-4 -top-1"
                onClick={() => {
                  setQuery("")
                  setShowSearchState(false)
                  setShowSearchResultsState(false)
                }}
              >
                <Close20 />
              </button>
            </>
          )}
        </div>
        {/* Search Resulsts */}
        <SearchResultsOverlay
          isMobile={true}
          categories={categories}
          setShowSearchResultsState={setShowSearchResultsState}
          showSearchResultsState={showSearchResultsState}
        />
      </div>
    </div>
  )
}

export default MobileNavOld
