import React, { Fragment, useState } from "react"
import { ChevronDown16 } from "@carbon/icons-react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const MobileMenuAccordionOld = ({ item }) => {
  const [isOpenState, setIsOpenState] = useState(false)
  return item.sub_navigation?.length > 0 ? (
    <>
      <button
        className="flex w-full font-bold text-left text-gray-800 border-t btn hover:text-primary border-100"
        onClick={() => setIsOpenState(isOpenState ? false : true)}
      >
        {item.title}
        <ChevronDown16
          className={`inline-block h-6 ml-auto -mt-0.25 align-middle transition-transform duration-300 transform ${
            isOpenState === true ? "rotate-180" : ""
          }`}
        />
      </button>
      <div className={`${isOpenState === false ? "overflow-hidden h-0" : ""}`}>
        {item.sub_navigation?.map((subitem, index) => (
          <Fragment key={index}>
            <Link
              className="block pl-8 font-semibold text-gray-800 bg-gray-50 btn hover:text-primary"
              link={subitem.link}
            >
              {subitem.title}
            </Link>
            {subitem.sub_navigation?.length > 0 &&
              subitem.sub_navigation?.map((secsubitem, index) => (
                <Link
                  key={index}
                  className="block pl-12 text-gray-800 bg-gray-50 btn hover:text-primary"
                  link={secsubitem.link}
                >
                  {secsubitem.title}
                </Link>
              ))}
          </Fragment>
        ))}
      </div>
    </>
  ) : (
    <Link
      className="flex w-full font-bold text-left text-gray-800 border-t btn hover:text-primary border-100"
      link={`/${item.link?.cached_url}`}
    >
      {item.title}
    </Link>
  )
}

export default MobileMenuAccordionOld
