const websiteRegionsMapping = [
  {
    url: "https://rothenberger.com/au-en",
    hrefLang: "en-au",
  },
  {
    url: "https://rothenberger.com/be-fr",
    hrefLang: "fr-be",
  },
  {
    url: "https://rothenberger.com/be-nl",
    hrefLang: "nl-be",
  },
  {
    url: "https://rothenberger.com/bg-bg",
    hrefLang: "bg-bg",
  },
  {
    url: "https://rothenberger.com/br-pt",
    hrefLang: "pt-br",
  },
  {
    url: "https://rothenberger.com/cz-cz",
    hrefLang: "cs-cz",
  },
  {
    url: "https://rothenberger.com/de-de",
    hrefLang: "de-de",
  },
  {
    url: "https://rothenberger.com/es-es",
    hrefLang: "es-es",
  },
  {
    url: "https://rothenberger.com/fr-fr",
    hrefLang: "fr-fr",
  },
  {
    url: "https://rothenberger.com/in-en",
    hrefLang: "en-in",
  },
  {
    url: "https://rothenberger.com/it-it",
    hrefLang: "it-it",
  },
  {
    url: "https://rothenberger.com/hu-hu",
    hrefLang: "hu-hu",
  },
  {
    url: "https://rothenberger.com/nl-nl",
    hrefLang: "nl-nl",
  },
  {
    url: "https://rothenberger.com/at-de",
    hrefLang: "de-at",
  },
  {
    url: "https://rothenberger.com/pl-pl",
    hrefLang: "pl-pl",
  },
  {
    url: "https://rothenberger.com/pt-pt",
    hrefLang: "pt-pt",
  },
  {
    url: "https://rothenberger.com/ro-ro",
    hrefLang: "ro-ro",
  },
  {
    url: "https://rothenberger.com/scandi-en",
    hrefLang: "en-no",
  },
  {
    url: "https://rothenberger.com/scandi-en",
    hrefLang: "en-dk",
  },
  {
    url: "https://rothenberger.com/scandi-en",
    hrefLang: "en-fi",
  },
  {
    url: "https://rothenberger.com/ch-de",
    hrefLang: "de-ch",
  },
  {
    url: "https://rothenberger.com/rs-en",
    hrefLang: "en-rs",
  },
  {
    url: "https://rothenberger.com/sk-sk",
    hrefLang: "sk-sk",
  },
  {
    url: "https://rothenberger.com/za-en",
    hrefLang: "en-za",
  },
  {
    url: "https://rothenberger.com/ch-fr",
    hrefLang: "fr-ch",
  },
  {
    url: "https://rothenberger.com/ch-it",
    hrefLang: "it-ch",
  },
  {
    url: "https://rothenberger.com/tr-tr",
    hrefLang: "tr-tr",
  },
  {
    url: "https://rothenberger.com/ae-en",
    hrefLang: "en-ae",
  },
  {
    url: "https://rothenberger.com/gb-en",
    hrefLang: "en-gb",
  },
  {
    url: "https://rothenberger.com/us-en",
    hrefLang: "en-us",
  },
]

module.exports = {
  websiteRegionsMapping: websiteRegionsMapping,
}
