import React, { useState, useEffect } from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import UserPanel from "../../../../components/molecules/UserPanel"
import { useTranslation } from "react-i18next"

import {
  Close20,
  Menu20,
  Search20,
  Location20,
  EarthEuropeAfricaFilled20,
} from "@carbon/icons-react"
import SearchResultsOverlay from "./SearchResultsOverlay"
import MobileMenuAccordion from "./MobileMenuAccordion"
import Search from "./Search"
import { Helmet } from "react-helmet"
import Logo from "./Logo"

const websitesMapping = require("../../../../../config/websites.js")
const websites = websitesMapping["websitesMapping"]

const MobileNav = ({
  categories,
  setShowSearchResultsState,
  showSearchResultsState,
  setMenuState,
  menuState,
  navData,
  menuClickHandler,
  setQuery,
  query,
}) => {
  const [showSearchState, setShowSearchState] = useState(false)
  const [openAccordionState, setOpenAccordionState] = useState(false)
  const [currentWebsite, setCurrentWebsite] = useState(null)
  const { t, i18n } = useTranslation()
  const dealerLinks = process.env.GATSBY_DEALER_SEARCH_PAGE_PATH
    ? JSON.parse(process.env.GATSBY_DEALER_SEARCH_PAGE_PATH)
    : null

  useEffect(() => {
    if (typeof window !== "undefined") {
      setCurrentWebsite(
        websites.find(
          (w) =>
            w.url.replace("https://rothenberger.com", "").split("/")[1] ===
            window.location.pathname.split("/")[1]
        ) || null
      )
    }
    setShowSearchState(showSearchResultsState)
  }, [showSearchResultsState])

  return (
    <div className="block bg-white lg:hidden">
      <div className="relative flex flex-row justify-between">
        <div className="h-12 px-4 pt-4 pb-2">
          <Logo />
        </div>
        <div>
          <button
            className="px-2 py-3 "
            onClick={() => {
              setShowSearchState(true)
            }}
          >
            <Search20 className="inline-block -mt-0.5 align-middle" />
          </button>
          <UserPanel mobileVersion={true} />

          <button
            className="pl-2 pr-4 py-3 "
            onClick={() => {
              setMenuState(menuState === "mobile" ? null : "mobile")
            }}
          >
            {menuState === "mobile" ? (
              <Close20 className="inline-block -mt-0.5 align-middle" />
            ) : (
              <Menu20 className="inline-block -mt-0.5 align-middle" />
            )}
          </button>
        </div>
        <div
          className={`absolute top-12 z-50 w-full overflow-y-auto flex flex-col ${
            menuState === "mobile" ? "" : "hidden"
          }`}
          style={{ height: "calc(100vh - 3rem)" }}
        >
          <div className="bg-white shadow-sm">
            {menuState === "mobile" && (
              <Helmet
                htmlAttributes={{
                  class: "overflow-x-hidden overflow-hidden",
                }}
                bodyAttributes={{
                  class:
                    "theme-default font-body overflow-x-hidden overflow-hidden",
                }}
              />
            )}
            {navData && (
              <div>
                {navData?.map((item, index) => (
                  <MobileMenuAccordion
                    openAccordionState={openAccordionState}
                    setOpenAccordionState={setOpenAccordionState}
                    index={index}
                    key={index}
                    item={item}
                  />
                ))}
              </div>
            )}
            <div className="flex flex-row text-gray-400 bg-gray-800">
              {currentWebsite && (
                <Link
                  link="https://rothenberger.com/websites"
                  className="w-1/2 btn hover:text-white"
                >
                  <EarthEuropeAfricaFilled20 className="inline-block mr-2 -mt-0.5 align-middle" />
                  {currentWebsite && currentWebsite.title}
                </Link>
              )}
              {dealerLinks && dealerLinks[i18n.language] && (
                <Link
                  link={dealerLinks[i18n.language]}
                  className="w-1/2  btn hover:text-white text-right"
                >
                  {t("generic.dealer_map.title")}
                  <Location20 className="inline-block ml-1 -mt-0.5 align-middle" />
                </Link>
              )}
            </div>
          </div>
          <div
            className="flex w-full h-full bg-black bg-opacity-50 backdrop-blur-[2px]"
            onClick={() => {
              setMenuState(null)
            }}
          ></div>
        </div>

        <div
          className={`absolute w-full transition-all duration-300 ${
            showSearchState ? "" : "transform -translate-y-full"
          }`}
        >
          {showSearchState && (
            <>
              <Search
                menuClickHandler={menuClickHandler}
                setMenuState={setMenuState}
                menuState={menuState}
                categories={categories}
                setShowSearchResultsState={setShowSearchResultsState}
                showSearchResultsState={showSearchResultsState}
                setQuery={setQuery}
                query={query}
                isMobile={true}
              />
              <button
                className="absolute right-0 p-4 -top-1"
                onClick={() => {
                  setQuery("")
                  setShowSearchState(false)
                  setShowSearchResultsState(false)
                }}
              >
                <Close20 />
              </button>
            </>
          )}
        </div>
        {/* Search Resulsts */}
        <SearchResultsOverlay
          isMobile={true}
          categories={categories}
          setShowSearchResultsState={setShowSearchResultsState}
          showSearchResultsState={showSearchResultsState}
        />
      </div>
    </div>
  )
}

export default MobileNav
