import React, { useRef, useState, useEffect } from "react"
import { Menu, Transition } from "@headlessui/react"
import { ChevronDown16, Search20, Renew20, Close20 } from "@carbon/icons-react"
// import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"

import useDebounce from "../../../../hooks/useDebounce"
import quickLinksMapping from "gatsby-theme-rothenberger/config/quickLinks.js"
import { navigate } from "gatsby"

import { connectStateResults } from "react-instantsearch-core"

const AlgoliaSearchState = connectStateResults(
  ({ isSearchStalled, searching }) => {
    return (
      <>
        {" "}
        {isSearchStalled ? (
          <Renew20 className="animate-spin transform rotate-180 inline-block -mt-0.5 align-middle" />
        ) : (
          <Search20 className="inline-block -mt-0.5 align-middle" />
        )}
      </>
    )
  }
)

const SearchOld = ({
  setMenuState,
  categories,
  setShowSearchResultsState,
  showSearchResultsState,
  setQuery,
  query,
  isMobile = false,
}) => {
  const inputElement = useRef(null)

  const [tmpQuery, setTmpQuery] = useState()
  const debouncedQuery = useDebounce(tmpQuery, 500)
  useEffect(() => {
    if (debouncedQuery !== undefined) {
      // console.warn("Set debouncedQuery: ", debouncedQuery)
      setQuery(debouncedQuery)
      if (typeof window !== "undefined") {
        // console.warn("Navigate: ", debouncedQuery)
        // navigate(`${window.location.pathname.substr(0,7)}?query=${encodeURI(debouncedQuery)}`)
      }
    }
  }, [debouncedQuery, setQuery])

  const [showQuicklinksState, setShowQuicklinksState] = useState(false)

  const SearchFocusHandler = () => {
    if (showSearchResultsState === false) {
      setShowQuicklinksState(true)
      setMenuState(null)
    }
  }

  useEffect(() => {
    if (inputElement.current && isMobile) {
      inputElement.current.focus()
    }
  }, [])

  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const website = `${process.env.GATSBY_GLOBAL_PATH_PREFIX}-${i18n.language}`
  const quickLinks = quickLinksMapping["quickLinks"][website]

  return (
    <div className={`flex flex-row flex-grow${isMobile ? "" : " px-4"}`}>
      <form
        className="relative w-full text-sm leading-6 text-gray-800 border border-gray-100"
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <button className="absolute left-0 px-4 py-3 btn " type="submit">
          <AlgoliaSearchState />
        </button>
        <input
          name="query"
          className="inline-block w-full py-3 pl-12 pr-4 leading-6 placeholder-gray-300"
          id="searchBox"
          ref={inputElement}
          type="text"
          placeholder={t("generic.search.placeholder")}
          autoComplete="off"
          defaultValue={query}
          onFocus={() => {
            SearchFocusHandler()
          }}
          onBlur={() => {
            setTimeout(() => {
              setShowQuicklinksState(false)
            }, 500)
          }}
          onChange={(e) =>
            e.target.value !== undefined
              ? (setTmpQuery(e.target.value),
                setShowQuicklinksState(false),
                setShowSearchResultsState(true))
              : ""
          }
        />
        {/* Quicklinks */}

        {quickLinks && quickLinks.length > 0 && (
          <div
            className={`${
              showQuicklinksState ? "block" : "hidden"
            } absolute z-50 w-full top-full px-grid shadow-lg bg-gray-50`}
          >
            <span className="block py-2 font-bold">Quicklinks</span>
            {quickLinks.map((quickLink) => (
              <Link
                className="block py-2 hover:text-primary"
                to={quickLink.url}
                key={quickLink.url}
              >
                {quickLink.name}
              </Link>
            ))}
          </div>
        )}
        {/* ENDE Quicklinks */}
      </form>
    </div>
  )
}

export default SearchOld
