import React, { useState, useLayoutEffect, useRef } from "react"

const StoryblokImage = ({
  image,
  className,
  style,
  fixedSize,
  aspectRatio,
  fitIn,
  title,
  alt,
  forceLoad,
  adaptToPixelRatio = false,
}) => {
  const [containerHeight, setContainerHeight] = useState(null)
  const [containerWidth, setContainerWidth] = useState(null)
  const [imageSize, setImageSize] = useState(null)
  const [heightInPercentage, setHeightInPercentage] = useState(null)
  const [loading, setLoading] = useState(true)
  const containerRef = useRef(null)

  const getImageSize = () => {
    const width = containerRef.current.clientWidth
    let dpiFactor = 1
    if (adaptToPixelRatio && window && window.devicePixelRatio) {
      dpiFactor = window.devicePixelRatio
    }

    setContainerWidth(Math.ceil((dpiFactor * width) / 50) * 50)
    if (aspectRatio && aspectRatio !== "") {
      const [ratioWidth, ratioHeight] = aspectRatio.split("by")
      const height = (containerWidth / ratioWidth) * ratioHeight
      const percentage = (100 * height) / containerWidth
      setContainerHeight((dpiFactor * height).toFixed(0))
      setImageSize(`${containerWidth}x${containerHeight}`)
      setHeightInPercentage(percentage)
      setLoading(false)
    } else {
      setImageSize(`${containerWidth}x0`)
      setLoading(false)
    }
  }

  useLayoutEffect(() => {
    if (containerRef.current) {
      getImageSize()
    }
  }, [containerRef, imageSize, loading])

  if (!image || !image.filename) {
    return <></>
  }

  let originalSrc = image.filename
  /* ? image.filename
    : image.src
    ? image.src
    : image.length > 0 && image
 */
  let imageService = "https://img2.storyblok.com"
  let fileName = originalSrc.replace("https://a.storyblok.com/", "")
  let focus = image.focus
    ? `filters:focal(${image.focus}):format(webp)`
    : "smart/filters:format(webp)"

  let imageSrc = ""

  if (!loading && fixedSize && fitIn) {
    imageSrc = `${imageService}/fit-in/${fixedSize}/filters:fill(ffffff):format(webp)/${fileName}`
  } else if (!loading && !fixedSize && fitIn) {
    imageSrc = `${imageService}/fit-in/${imageSize}/filters:fill(ffffff):format(webp)/${fileName}`
  } else if (!loading && fixedSize && !fitIn) {
    imageSrc = `${imageService}/${fixedSize}/${focus}/${fileName}`
  } else if (!loading && !fixedSize && !fitIn) {
    imageSrc = `${imageService}/${imageSize}/${focus}/${fileName}`
  }

  // todo: move to theme
  // reason: https://www.storyblok.com/docs/image-service
  // sb image service doesnt support svg at all
  if (originalSrc.endsWith(".svg")) {
    imageSrc = originalSrc
  }

  return (
    <div
      ref={containerRef}
      style={
        aspectRatio && aspectRatio !== ""
          ? { height: `${heightInPercentage}%` }
          : { height: "" }
      }
      className={`relative ${className}`}
    >
      {aspectRatio && aspectRatio !== "" && (
        <div
          className="w-full"
          style={{ paddingBottom: `${heightInPercentage}%` }}
        ></div>
      )}
      {!loading ? (
        <img
          loading={forceLoad ? "eager" : "lazy"}
          src={imageSrc}
          alt={alt}
          className={`${
            aspectRatio && aspectRatio !== "" ? "absolute top-0" : ""
          } ${className ? className : ""}`}
          title={title ? title : alt}
          style={style}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default StoryblokImage
