import React, { Fragment, useState, useEffect } from "react"
import {
  Login20,
  Logout20,
  UserAvatarFilled20,
  CircleDash20,
  ViewOff20,
} from "@carbon/icons-react"
import { Menu, Transition } from "@headlessui/react"
import { useAuth0 } from "@auth0/auth0-react"
import { useTranslation } from "react-i18next"

const loginAssistentUrl = (redirectUri, i18n) => {
  return `${process.env.GATSBY_LOGIN_ASSISTENT_HOST}/${i18n.language}/login?sp_name=${process.env.GATSBY_LOGIN_ASSISTENT_SP_NAME}&website=${process.env.GATSBY_GLOBAL_PATH_PREFIX}&redirect=${redirectUri}`
}

const UserLoading = () => {
  return (
    <div className="w-full btn px-2 flex items-center">
      <CircleDash20 className="inline-block -mt-0.5 align-middle animate-spin" />
    </div>
  )
}

const UserPanelInPreview = () => {
  return (
    <div className="w-full btn px-2 flex items-center">
      <ViewOff20 className="inline-block -mt-0.5 align-middle" />
    </div>
  )
}

const UserLoggedIn = ({ mobileVersion, user, logout, t }) => {
  return (
    <Menu as="div" className="relative">
      {({ open }) => (
        <>
          <Menu.Button className={`w-full block ${open ? "text-primary" : ""}`}>
            <div className="btn px-2 w-full block text-right hover:text-primary">
              {mobileVersion && <span>{user.name}&nbsp;</span>}
              <UserAvatarFilled20 className="inline-block -mt-0.5 align-middle" />
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-50 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-50 opacity-0"
          >
            <Menu.Items
              as="ul"
              className={`absolute right-0 left-auto z-50 min-w-full px-4 shadow-lg bg-gray-50 whitespace-nowrap origin-top-right${
                mobileVersion ? " top-0 -translate-y-full" : " top-full"
              }`}
            >
              <Menu.Item key={user.name} disabled as="li">
                <div className="block w-full px-0 text-gray-400 btn text-right">
                  {t("generic.welcome")} {user.name}
                </div>
              </Menu.Item>

              <Menu.Item key="user-logout" as="li">
                <div
                  onClick={() =>
                    logout({
                      returnTo: `${window.location.origin}/${
                        window.location.pathname.split("/")[1]
                      }`,
                    })
                  }
                  className="block w-full px-0 text-gray-800 cursor-pointer btn hover:text-primary text-right"
                >
                  {t("generic.logout")}
                  <Logout20 className="inline-block ml-2 -mt-0.5 align-middle" />
                </div>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

// const UserAnonymous = ({ mobileVersion, loginWithRedirect }) => {
//   return (
//     <button onClick={() => redirectToLogin()} className="btn hover:text-primary">
//       {mobileVersion && (
//         <span>Login&nbsp;</span>
//       )}
//       <UserAvatar20 className="inline-block -mt-0.5 align-middle" />
//     </button>
//   )
// }

const UserPanel = ({ mobileVersion }) => {
  const [redirectUri, setRedirectUri] = useState("")
  const [inPreviewMode, setInPreviewMode] = useState(false)
  const { i18n, t } = useTranslation()
  useEffect(() => {
    if (window && window.location && window.location.pathname) {
      setRedirectUri(window.location.pathname)
      if (
        window?.location?.pathname === "/storyblok-preview" ||
        window?.location?.pathname === "/storyblok-preview/"
      ) {
        setInPreviewMode(true)
      }
    }
    if (window && window.location && window.location.pathname) {
      setRedirectUri(window.location.pathname)
    }
  }, [])
  const { user, isAuthenticated, logout, isLoading } = useAuth0()

  if (inPreviewMode) return <UserPanelInPreview />
  if (isLoading) return <UserLoading />
  if (isAuthenticated)
    return (
      <UserLoggedIn
        mobileVersion={mobileVersion}
        user={user}
        logout={logout}
        t={t}
      />
    )

  return (
    <a
      className={`btn px-2 hover:text-primary`}
      href={loginAssistentUrl(redirectUri, i18n)}
      title="Login"
    >
      {/* {mobileVersion && <span>{t("generic.login")}&nbsp;</span>} */}
      <Login20 className="inline-block -mt-0.5 align-middle" />
    </a>
  )
}

export default UserPanel
