import React, { useState, useEffect, createConnector } from "react"

import MetaNavOld from "./MetaNavOld"
import SearchOld from "./SearchOld"
import SearchResultsOverlayOld from "./SearchResultsOverlayOld"
import MainNavOld from "./MainNavOld"
import MobileNavOld from "./MobileNavOld"
import LogoOld from "./LogoOld"
import UserPanel from "../../../../components/molecules/UserPanel"

import queryString from "query-string"
import algoliasearch from "algoliasearch"
import {
  InstantSearch,
  Configure,
  hits,
  connectHits,
  connectMenu,
} from "react-instantsearch-core"
import { useTranslation } from "react-i18next"

export const ALGOLIA_HITS_PER_PAGE = 10
export const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const VirtualMenu = connectMenu(() => null)

const HeaderOld = ({ navData }) => {
  const [menuState, setMenuState] = useState(null)
  const [showSearchResultsState, setShowSearchResultsState] = useState(false)

  const { i18n } = useTranslation()

  const menuClickHandler = (state) => {
    if (menuState === state) {
      setMenuState(null)
    } else {
      setMenuState(state)
    }
  }

  // Algolia Search
  let initialQuery = ""
  if (typeof window !== "undefined") {
    initialQuery = queryString.parse(window.location.search).query || ""
  }
  if (initialQuery) {
    if (showSearchResultsState !== true) {
      setShowSearchResultsState(true)
    }
  }
  const [query, setQuery] = useState(initialQuery)
  const options = {
    ...{
      query,
      filters: "",
      attributesToSnippet: ["name", "description:30"],
      //facetingAfterDistinct: true,
      hitsPerPage: 1000,
    },
  }

  const searchClient = {
    search(requests) {
      if (requests.every(({ params }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            processingTimeMS: 0,
          })),
        })
      }
      return algoliaClient.search(requests)
    },
  }
  // END of Algolia Search

  let categories = [{ title: "Universalwerkezeuge" }, { title: "Wartung" }]

  const website = `${process.env.GATSBY_GLOBAL_PATH_PREFIX}-${i18n.language}`

  return (
    <header className="w-full">
      <InstantSearch
        searchClient={searchClient}
        indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
      >
        <Configure
          query={options.query}
          filters={options.filters}
          facetingAfterDistinct={options.facetingAfterDistinct}
          attributesToSnippet={options.attributesToSnippet}
          hitsPerPage={options.hitsPerPage}
          distinct
        />
        <VirtualMenu attribute="website" defaultRefinement={website} />

        <MobileNavOld
          categories={categories}
          menuClickHandler={menuClickHandler}
          setShowSearchResultsState={setShowSearchResultsState}
          showSearchResultsState={showSearchResultsState}
          setMenuState={setMenuState}
          setQuery={setQuery}
          query={query}
          menuState={menuState}
          navData={navData}
        />
        {/* Meta-Nav */}
        <MetaNavOld menuClickHandler={menuClickHandler} menuState={menuState} />
        {/* ENDE Meta-Nav */}
        {/* Logo-Search-Bar */}

        <div className="relative hidden w-full bg-white border-b border-gray-100 md:block">
          <div className="flex flex-row py-4 mx-auto max-w-screen-2xl">
            <LogoOld className="h-12 px-4 pt-4 pb-2" />
            <SearchOld
              menuClickHandler={menuClickHandler}
              setMenuState={setMenuState}
              menuState={menuState}
              categories={categories}
              setShowSearchResultsState={setShowSearchResultsState}
              showSearchResultsState={showSearchResultsState}
              setQuery={setQuery}
              query={query}
            />
            {/* ENDE Suche */}
            <div className="flex flex-row">
              {/* <button className="btn hover:text-primary">
                <Help20 className="inline-block -mt-0.5 align-middle" />
              </button>
              <button className="btn hover:text-primary">
                <Notification20 className="inline-block -mt-0.5 align-middle" />
              </button> */}
              <UserPanel />
            </div>
          </div>
          {/* Search Resulsts */}
          <SearchResultsOverlayOld
            categories={categories}
            setShowSearchResultsState={setShowSearchResultsState}
            showSearchResultsState={showSearchResultsState}
            query={query}
          />
          {/* END Search Resulsts */}
        </div>
        {/* ENDE Logo-Search-Bar */}
        {/* Main-Nav */}
        <MainNavOld
          menuState={menuState}
          menuClickHandler={menuClickHandler}
          navData={navData}
        />
      </InstantSearch>
    </header>
  )
}

export default HeaderOld
